import React, { Fragment } from "react"
import * as R from "ramda"
import { graphql } from "gatsby"
import slugify from "slugify"
import LandingHero from "../components/landingHero"
import LiftContent from "../components/liftContent"
import Card from "../components/card"
import CollectionGrid from "../components/collectionGrid"
import Seo from "../components/seo"
import LayoutController from "../components/layoutController"

const LandingPage = ({ data }) => {
  const pageContent = R.path(["laskeutumissivu"], data)
  const language = R.path(["node_locale"], pageContent)
  const title = R.path(["title"], pageContent)
  const lead = R.path(["contentText", "contentText"], pageContent)
  let pastExpos = R.path(["messut", "nodes"], data)
  const pastExpoTitle = R.path(["pastExpoTitle"], pageContent)
  const pastExpoText = R.path(["pastExpoText"], pageContent)
 
  pastExpos.forEach(item => {
    return (item.nr = parseInt(item.title.substring(item.title.length - 4)))
  })
  const sortByNameCaseInsensitive = R.sortWith([R.descend(R.prop("nr"))])
  pastExpos = sortByNameCaseInsensitive(pastExpos)

  return (
    <Fragment>
      <LayoutController language={language}> 
        <Seo
          seoTitle={title}
          seoDesc={lead}
          url={typeof window !== "undefined" ? window.location.href : ""}
          //image={mainImageSEO}
        />
        <section>
          <header className="bg-blue pt-10 pb-[150px] md:pb-[280px] px-5">
            <div className="max-w-3xl mx-auto text-center">
              {title && <h1>{title}</h1>}
              {lead && <p>{lead}</p>}
            </div>
          </header>
          <div className="relative z-10 mt-[-150px] md:mt-[-280px] px-5">
            <LandingHero pageContent={pageContent} />
          </div>
          {pastExpos && (
            <div className="bg-beige">
              <div className="max-w-5xl mx-auto py-16 px-5">
                <header className="max-w-2xl mx-auto mb-10 text-center">
                  {pastExpoTitle && <h2>{pastExpoTitle}</h2>}
                  {pastExpoText && <p>{pastExpoText}</p>}
                </header>
                <CollectionGrid tight>
                  {pastExpos.map(expo => (
                    <Card
                      boxed
                      title={expo.title}
                      image={R.path(["gatsbyImageData"], expo.mainImage)}
                      url={`${expo.slug}/virtuaalimessut/`}
                      text={expo.virtualContentText}
                      language='fi'
                    />
                  ))}
                </CollectionGrid>
              </div>
            </div>
          )}
          <LiftContent pageContent={pageContent} />
        </section>
      </LayoutController>
    </Fragment>
  )
}

export default LandingPage
// eslint-disable-next-line no-undef
export const pageQuery = graphql`
  query landingPageQuery($slug: String) {
    laskeutumissivu: contentfulLaskeutumissivu(slug: { eq: $slug }) {
      node_locale
      title
      contentText {
        contentText
      }
      heroTitle
      heroText {
        heroText
      }
      heroLink
      heroLinkLabel
      media {
        id
        file {
          contentType
        }
        file {
          url
        }
        gatsbyImageData(quality: 80, layout: CONSTRAINED)
      }
      videoPosterImage {
        file {
          url
        }
      }
      pastExpoTitle
      pastExpoText
      navigation {
        ... on Node {
          ... on ContentfulKategoriasivu {
            __typename
            id
            slug
            title
          }
          ... on ContentfulArtikkelisivu {
            __typename
            id
            slug
            title
          }
          ... on ContentfulPerussivu {
            __typename
            id
            slug
            title
          }
        }
      }
      liftContent {
        ... on Node {
          ... on ContentfulNostolista {
            __typename
            id
            color
            buttonText
            buttonAction {
              ... on Node {
                ... on ContentfulKategoriasivu {
                  __typename
                  id
                  slug
                }
                ... on ContentfulArtikkelisivu {
                  __typename
                  id
                  slug
                }
                ... on ContentfulKohdesivu {
                  __typename
                  id
                  slug
                }
              }
            }
            title
          }
          ... on ContentfulVarinosto {
            __typename
            id
            backgroundColor
            title
            contentText {
              contentText
            }
            ctaButton1Text
            ctaButton1Action {
              ... on Node {
                ... on ContentfulArtikkelisivu {
                  __typename
                  slug
                  mainImage {
                    id
                    file {
                      url
                    }
                    gatsbyImageData(quality: 80, layout: CONSTRAINED)
                  }
                }
                ... on ContentfulKategoriasivu {
                  __typename
                  slug
                  mainImage {
                    id
                    file {
                      url
                    }
                    gatsbyImageData(quality: 80, layout: CONSTRAINED)
                  }
                }
              }
            }
          }
          ... on ContentfulSisaltoelementti {
            __typename
            id
            backgroundColor
            title
            contentText {
              contentText
            }
            image {
              id
              file {
                url
              }
              gatsbyImageData(quality: 80, layout: CONSTRAINED)
            }
            ctaButtonText
            ctaButtonAction
          }
          ... on ContentfulVideonosto {
            __typename
            id
            title
            contentText {
              contentText
            }
            videoUrl
            thumbNail {
              id
              file {
                url
              }
              gatsbyImageData(quality: 80, layout: CONSTRAINED)
            }
            ctaButtonText
            buttonSlug
          }
          ... on ContentfulTekstilinkkilista {
            __typename
            id
            title
            contentText {
              contentText
            }
            liftList {
              ... on Node {
                ... on ContentfulArtikkelisivu {
                  id
                  title
                  slug
                }
                ... on ContentfulKategoriasivu {
                  id
                  slug
                  title
                }
                ... on ContentfulKohdesivu {
                  id
                  name
                  slug
                }
              }
            }
          }
          ... on ContentfulTekstikentta {
            __typename
            title
            text {
              text
            }
            ctaButtonText
            ctaButtonSlug
            wide
          }
          ... on ContentfulKohdekartta {
            __typename
            id
            title
            premiseYear
            map {
              gatsbyImageData(quality: 80, layout: CONSTRAINED)
            }
          }
          ... on ContentfulAnimoidutNostot {
            __typename
            id
            title
            lifts {
              id
              title
              text {
                text
              }
              buttonText
              buttonLink
              buttonAction {
                ... on Node {
                  ... on ContentfulKategoriasivu {
                    __typename
                    id
                    slug
                  }
                  ... on ContentfulArtikkelisivu {
                    __typename
                    id
                    slug
                  }
                  ... on ContentfulKohdesivu {
                    __typename
                    id
                    slug
                  }
                }
              }
            }
          }
        }
      }
    }
    messut: allContentfulMessu(
      filter: {
        status: { eq: "mennyt" }
        slug: { ne: "jaerjestelmaetiedosto" }
      }
    ) {
      nodes {
        title
        slug
        node_locale
        status
        contentTextShort
        mainImage {
          gatsbyImageData(layout: CONSTRAINED, width: 400)
        }
        articlesTitle
        premisesTitle
        virtualContentText
        premises {
          ... on Node {
            ... on ContentfulKohdesivu {
              slug
              isVirtual
              name
              mainImage {
                gatsbyImageData(layout: CONSTRAINED, width: 400, quality: 80)
              }
            }
          }
        }
      }
    }
  }
`
