import React from "react"
import * as R from "ramda"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Box from "@material-ui/core/Box"
import Button from "../components/button"
import * as styles from "./landingHero.module.scss"
import ReactMarkdown from "react-markdown"

const LandingHero = ({ pageContent }) => {
  const title = R.path(["title"], pageContent)
  const heroTitle = R.path(["heroTitle"], pageContent)
  const heroText = R.path(["heroText", "heroText"], pageContent)
  const heroLink = R.path(["heroLink"], pageContent)
  const heroLinkLabel = R.path(["heroLinkLabel"], pageContent)
  const image = R.path(["media", "gatsbyImageData"], pageContent)
  const video = R.path(["media", "file"], pageContent)
  const videoPoster = R.path(["videoPosterImage", "file", "url"], pageContent)
  const mediaType = R.path(["media", "file", "contentType"], pageContent)
  const navigationItems = R.path(["navigation"], pageContent)

  
  return mediaType === "video/mp4" ? (
    <Box className={styles.landingHero} component="section">
      <div className={styles.container}>
        {video && (
          <video width="100%" controls poster={videoPoster}>
            <source src={video.url} type="video/mp4" />
          </video>
        )}

        {navigationItems && (
          <nav className={styles.navigation}>
            <ul>
              {navigationItems.map((item, index) => (
                <li>
                  <Link to={`/${item.slug}`}>{item.title}</Link>
                </li>
              ))}
            </ul>
          </nav>
        )}
      </div>
      <div className="mt-10 text-center max-w-3xl mx-auto">
        <h2>{heroTitle}</h2>
        <p><ReactMarkdown children={heroText} softBreak="br" /></p>
        <Button url={heroLink}>{heroLinkLabel}</Button>
      </div>
    </Box>
  ) : (
    <Box className={styles.landingHero} component="section">
      <div className={styles.container}>
        {image && (
          <GatsbyImage
            image={{ ...image, aspectRatio: 8 / 5 }}
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              width: "100%",
              zIndex: "-1",
              height: "100%",
            }}
            imgStyle={{ objectFit: "cover", objectPosition: "0% 0%" }}
          />
        )}

        {navigationItems && (
          <nav className={styles.navigation}>
            <ul>
              {navigationItems.map((item, index) => (
                <li>
                  <Link to={`/${item.slug}`}>{item.title}</Link>
                </li>
              ))}
            </ul>
          </nav>
        )}
      </div>
      <div className="mt-10 text-center max-w-3xl mx-auto">
        <h2>{heroTitle}</h2>
        <p><ReactMarkdown children={heroText} softBreak="br" /></p>
        <Button url={heroLink}>{heroLinkLabel}</Button>
      </div>
    </Box>
  )

  // if (mediaType === 'image/jpeg' || 'image/gif') {
  //   return (
  //     <Box className={styles.landingHero} component="section">
  //       <div className={styles.container}>
  //         {image &&
  //           <Image
  //             style={{ position: 'absolute', top: '0', left: '0', width: '100%', zIndex: '-1', height: '100%' }}
  //             imgStyle={{ objectFit: 'cover', objectPosition: '0% 0%' }}
  //             fluid={{ ...image, aspectRatio: 8 / 5 }}
  //           />
  //         }

  //         {title &&
  //           <div className={styles.textContainer}>
  //             <h1>{title}</h1>
  //           </div>
  //         }

  //         {navigationItems &&
  //           <nav className={styles.navigation}>
  //             <ul>
  //               {navigationItems.map((item, index) => (
  //                 <li>
  //                   <Link to={`/${item.slug}`}>
  //                     {item.title}
  //                   </Link>
  //                 </li>
  //               ))}
  //             </ul>
  //           </nav>
  //         }
  //       </div>
  //     </Box >
  //   )
  // } else if (mediaType === 'video/mp4') {
  //   return (
  //     <Box className={styles.landingHero} component="section">
  //       <div className={styles.container}>
  //         <h1>idep</h1>
  //         {video &&
  //           <video width="100%" autoPlay loop muted>
  //             <source src={video.url} type="video/mp4" />
  //           </video>
  //         }

  //         <div className={styles.textContainer}>
  //           <h1>{title}</h1>
  //         </div>

  //         {navigationItems &&
  //           <nav className={styles.navigation}>
  //             <ul>
  //               {navigationItems.map((item, index) => (
  //                 <li>
  //                   <Link to={`/${item.slug}`}>
  //                     {item.title}
  //                   </Link>
  //                 </li>
  //               ))}
  //             </ul>
  //           </nav>
  //         }
  //       </div>
  //     </Box >
  //   )
  // } else {
  //   return
  // }
}

export default LandingHero
